import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-account-access-permission',
  templateUrl: './account-access-permission.component.html',
  styleUrls: ['./account-access-permission.component.scss']
})
export class AccountAccessPermissionComponent implements OnInit {

constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.document.body.classList.add('accout-access-permi');
  }

}
