import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { CommonService } from 'src/app/services/common.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  subscriptions = [];
  features = [];
  userdata;
  RoleAccess = [];
  Appfeatures = [];
  feature = {};

  constructor(public auth: AuthenticationService, public common: CommonService, public afs: AngularFirestore, private router: Router) { 

    this.getMenus()
  }

  ngOnInit() {
    $(document).ready(function(){ 
      $(".mobile-menu").click(function(){
        $("body").toggleClass("menu-active");
       })
       $(".navbar-nav li a:not('.dropdown-toggle'), ul.menu.menu--main li a:not('.dropdown-toggle'), a:not('.dropdown-toggle')").click(function(){
        $("body").removeClass("menu-active");
       })
  });
  }

  getMenus() {
    this.feature = {};
    this.features = [];
    this.Appfeatures = []; 
    this.RoleAccess = [];
    if (this.auth.user) {
      this.auth.user.subscribe((user) => {
        if (user) {
          this.userdata = user;
         
        //user is manufacturer : user.role
          if(this.userdata.role == 'Manufacturer'){
            this.features = [];
            var sub = this.afs.collection(`${environment.firestorePath}/subscription`).doc('f2').valueChanges();
            sub.subscribe(subscription => {
          //    console.log(subscription);
              var arrayFeat = subscription['features'];
              arrayFeat.map(a => {
//console.log(a['name'].toLowerCase().replace('-', ' '));
                this.afs.collection(`${environment.firestorePath}/features`, ref => ref.where('name', '==', a['name'].toLowerCase().replace('-', ' '))).valueChanges().subscribe((p) => { //.where('roles', '==', user.role.toLowerCase())
                  this.feature = JSON.parse(JSON.stringify(p));
                  this.features.push(p[0]);
                  //this.addfeatures(this.feature);
                  //this.features
                  //console.log(this.features);
                })
              })
            });        
          }
          else{
            this.features = [];
            this.afs.collection(`${environment.firestorePath}/features`, ref => ref.where('roles', '==', user.role.toLowerCase())).valueChanges().subscribe((p) => {
              this.feature = JSON.parse(JSON.stringify(p));
              this.features.push(p[0]);
              //this.addfeatures(this.feature);
              //this.features
             // console.log(this.features);
            })
          }
        }
      });
    }
  }

  logout() { this.auth.signOut(); }

  gotoProfile(){
    this.router.navigate(['user/profile']);
  }

}
