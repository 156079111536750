import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {



  title = 'owlcarouselinAngular';  
  slides = [
    {img: '../../../assets/events/list-event-img-large.png', title: 'AIA'},
    {img: '../../../assets/events/list-event-img-large.png', title: 'AIA'},
    {img: '../../../assets/events/list-event-img-large.png', title: 'AIA'},
    {img: '../../../assets/events/list-event-img-large.png', title: 'AIA'},
    {img: '../../../assets/events/list-event-img-large.png', title: 'AIA'},
    {img: '../../../assets/events/list-event-img-large.png', title: 'AIA'},
    {img: '../../../assets/events/list-event-img-large.png', title: 'AIA'}
  ];  
  
  SlideOptions = { items: 1, dots: false, nav: true };  
  CarouselOptions = { items: 1, dots: false, nav: true };  

  months = ['January','February', 'March', 'April', 'May', 'June', 'July', 'August','September', 'October', 'November', 'December'];
  selectyear = ['2019','2020','2021', '2022'];
eventType = ['abc' , 'xyz'];
location = ['abc' , 'xyz'];
industryType = ['abc', 'xyz'];

date = new Date();
  constructor() { }

  ngOnInit() {
    $(document).ready(function(){ 
      $(".mobile-menu").click(function(){
        $("body").toggleClass("menu-active");
       })
       $(".navbar-nav li a:not('.dropdown-toggle'), ul.menu.menu--main li a:not('.dropdown-toggle'), a:not('.dropdown-toggle')").click(function(){
        $("body").removeClass("menu-active");
       })
  });

  }

}
