import { Component } from '@angular/core';
import { CommonService } from './services/common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Auufa';
  show: boolean = true;

  constructor(private commonService: CommonService, public router: Router) {
    

  }

  checkRouter(){
    if (this.router.url.includes('events')) {
      return false;
   // }else if (this.router.url.includes('login')) {
     // return false;
    //}else if (this.router.url.includes('register')) {
     // return false; access-permission
    }  
      else if (this.router.url.includes('subscription')) {
    return false;
  } else if (this.router.url.includes('signin-information')) {
    return false;
  } else if (this.router.url.includes('access-permission')) {
    return false;
  } else if (this.router.url.includes('account-settings')) {
    return false;
  } else if (this.router.url.includes('subscription-payment')) {
    return false;
  } else if (this.router.url.includes('account-overview')) {
    return false;
  } else if (this.router.url.includes('firms-profile')) {
    return false;
  }
else if (this.router.url.includes('database')) {
  return false;
} 
else if (this.router.url.includes('search')) {
  return false;
}  
    else{
      return true;
    }
  }
  checkRouter2(){
    if (this.router.url.includes('events')) {
      return false;
    }else if (this.router.url.includes('login')) {
      return false;
    //}else if (this.router.url.includes('register')) {
     // return false;
    }
    else if (this.router.url.includes('subscription-payment')) {
      return true;
    }
    else if (this.router.url.includes('subscription')) {
    return false;
  }
    else{
      return true;
    }
  }

  checkRouter3(){
    if (this.router.url.includes('signin-information')) {
      return true;
    } else if (this.router.url.includes('access-permission')) {
      return true;
    } else if (this.router.url.includes('account-settings')) {
      return true;
    } else if (this.router.url.includes('subscription-payment')) {
      return true;
    } else if (this.router.url.includes('account-overview')) {
      return true;
    } else if (this.router.url.includes('database')) {
      return true;
    } else if (this.router.url.includes('search')) {
      return true;
    } else if (this.router.url.includes('firms-profile')) {
      return true;
    } else{
      return false;
    }
  }

}
