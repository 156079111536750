import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './authentication/login/login.component';
import { RegisterComponent } from './authentication/register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';


// @angular/fire/ Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { HomeComponent } from './home/home.component';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AuthenticationService } from './authentication/services/authentication.service';
import { CommonService } from './services/common.service';
import{ OwlModule } from 'ngx-owl-carousel';
import { EventsComponent } from './components/events/events.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import {CalendarModule} from '@syncfusion/ej2-angular-calendars';
import { EventListComponent } from './components/event-list/event-list.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSelectModule, MatTooltipModule } from '@angular/material';
import { SearchComponent } from './components/search/search.component';
import { DatabaseComponent } from './components/database/database.component';
import { SignInInformationComponent } from './components/sign-in-information/sign-in-information.component';
import { HeaderAccountComponent } from './components/header-account/header-account.component';
import { AccountAccessPermissionComponent } from './components/account-access-permission/account-access-permission.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { SubscriptionPaymentComponent } from './components/subscription-payment/subscription-payment.component';
import { AccountOverviewComponent } from './components/account-overview/account-overview.component';
import { CommonModule } from '@angular/common';
import { TreeViewComponent } from './components/csicodes/tree-view.component';
import { CsicodesComponent } from './components/csicodes/csicodes.component';
import { MultipleComponent } from './search/multiple/multiple.component';
import { Ng5SliderModule } from 'ng5-slider';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FirmsProfileComponent } from './components/firms-profile/firms-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    EventsComponent,
    SubscriptionComponent,
    EventListComponent,
    EventCardComponent,
    SearchComponent,
    DatabaseComponent,
    //SignInInformationComponent,
    HeaderAccountComponent,
    AccountAccessPermissionComponent,
    //AccountSettingsComponent,
    SubscriptionPaymentComponent,
    AccountOverviewComponent, MultipleComponent, CsicodesComponent,TreeViewComponent, FirmsProfileComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    NoopAnimationsModule,
    OwlModule, 
    CalendarModule,
    NgSelectModule,
    MatSelectModule,
    MatTooltipModule,
    ToastrModule.forRoot({  
      positionClass:'toast-top-center'
    }),
    NgbModule,
    Ng5SliderModule
  ],
  providers: [AuthenticationService, CommonService],
  bootstrap: [AppComponent]
})
export class AppModule { }
