import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  title = 'owlcarouselinAngular';  
  slides = [
    {img: '../../../assets/events/nybuild.PNG', title: 'NEW YORK BUILD EXPO 2020'},
    {img: '../../../assets/events/a20.PNG', title: '#A20CON'},
    {img: '../../../assets/events/hanyc.PNG', title: 'HYNYC'},
    {img: '../../../assets/events/auffaevnt_1.PNG', title: 'aUUfa Building Digital Community'},
    {img: '../../../assets/events/auffaevnt_0.PNG', title: 'aUUfa Architecture Event'},
    {img: '../../../assets/events/auffaevnt.PNG', title: 'aUUFa Event 2020'}
  ];  
  
  SlideOptions = { items: 3, dots: true, nav: true, responsive: { 0: { items: 1 }, 720: { items: 2 }, 991: { items: 3 } } };  
  CarouselOptions = { items: 3, dots: true, nav: true };  


  //public minDate: Date = new Date ("05/07/2017");
  //public maxDate: Date = new Date ("08/27/2017");
 // public value: Date = new Date ("05/16/2017");

   //industry = ['Architecture', 'Engineering', 'Interior Design', 'Construction', 'RE Development', 'Other Designers/Creatives ', 'Products'];
  months = ['January','February', 'March', 'April', 'May', 'June', 'July', 'August','September', 'October', 'November', 'December'];
selectyear = ['2019','2020','2021', '2022'];
eventType = ['abc' , 'xyz'];
location = ['abc' , 'xyz'];

date = new Date();
  constructor() { }

  ngOnInit() {
    $(document).ready(function(){ 
      $(".mobile-menu").click(function(){
        $("body").toggleClass("menu-active");
       })
       $(".navbar-nav li a:not('.dropdown-toggle'), ul.menu.menu--main li a:not('.dropdown-toggle'), a:not('.dropdown-toggle')").click(function(){
        $("body").removeClass("menu-active");
       })
  });
  }

}
