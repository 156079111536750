import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss']
})
export class EventListComponent implements OnInit {


  months = ['January','February', 'March', 'April', 'May', 'June', 'July', 'August','September', 'October', 'November', 'December'];
  selectyear = ['2019','2020','2021', '2022'];
  eventType = ['abc' , 'xyz'];
  location = ['abc' , 'xyz'];
  industryType = ['abc', 'xyz'];
  
  constructor() { }

  date = new Date();
  ngOnInit() {
    $(document).ready(function(){ 
      $(".mobile-menu").click(function(){
        $("body").toggleClass("menu-active");
       })
       $(".navbar-nav li a:not('.dropdown-toggle'), ul.menu.menu--main li a:not('.dropdown-toggle'), a:not('.dropdown-toggle')").click(function(){
        $("body").removeClass("menu-active");
       })
  });

  }

}
