import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-subscription-payment',
  templateUrl: './subscription-payment.component.html',
  styleUrls: ['./subscription-payment.component.scss']
})
export class SubscriptionPaymentComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document) {}
    

  ngOnInit() {
    this.document.body.classList.add('subscription-payment');
  }

}



