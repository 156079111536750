import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { inject } from '@angular/core/testing';
import * as $ from 'jquery';

@Component({
  selector: 'app-firms-profile',
  templateUrl: './firms-profile.component.html',
  styleUrls: ['./firms-profile.component.scss']
})
export class FirmsProfileComponent implements OnInit {

   category = ['Category/CSI #', 'Category/CSI #2', 'Category/CSI #3'];
   productname = ['One', 'Two', 'Three'];
   years = ['2018', '2019', '2020', '2021'];
   cfpt = ["Red/Brown", 'Gold', 'Platinum', 'texture'];
   materials = ["Type 1", 'Type 2', 'Type 3', 'Type 4'];
   size = ["5", "6", "7","8","9"]

constructor(@Inject(DOCUMENT) private document: Document) { }

  ngOnInit() {
    this.document.body.classList.add('firms-profile');
    
    $(document).ready(function(){ 

     $(".products li.slide-container").each(function(){
       var a = $(this), b=a.find("ul.expanded-items > li").length;
       if(b>3){ a.addClass("multi-rows");
       }else if(b>2){ a.addClass("two-rows");
      } else{ a.addClass("signle-rows");}
     })


     $(".press-release li.slide-container").each(function(){
      var a = $(this), b=a.find("ul.expanded-items > li").length;
      if(b>2){ a.addClass("multi-rows");
     } else{ a.addClass("signle-rows");}
    })

    $(".events li.slide-container").each(function(){
      var a = $(this), b=a.find("ul.expanded-items > li").length;
      if(b>6){ a.addClass("multi-rows");}
      else if(b>4){ a.addClass("three-rows");}
      else if(b>2){ a.addClass("two-rows");
     } else{ a.addClass("signle-rows");}
    })    

    });

  }

}
