import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { TreeModule } from 'angular-tree-component';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';


@Component({
  selector: 'tree-view',
  templateUrl: './csicodes.component.html',
  styleUrls: ['./csicodes.component.css']
})

export class CsicodesComponent implements OnInit {

  @Output() clickNode = new EventEmitter<any>();

  public treeData = [];
  public collapseAll: boolean;
  public selectAll: boolean;
  Loaded: Promise<boolean>;

  click(node: any) {
    //console.log(node);
    this.clickNode.emit(node);
  }

  onChange(data) {
    console.log(data);
  }



  constructor(private afs: AngularFirestore) {
    this.getData();
  }

  ngOnInit() {
    
  }

  getData() {
    //this.treeData = []; 
    this.afs.collection('Development/v1/CSIcodes').valueChanges().subscribe(c => {
      //this.afs.collection('/development/ZmNgCjdfee9gbi4pxsoY/CSIcodesv2').valueChanges().subscribe(c => {
      console.log(c);
      this.treeData = JSON.parse(JSON.stringify(c));
      this.Loaded = Promise.resolve(true); 
      //console.log(this.treeData);
      // const cloned = this.data.map(x => Object.assign([], x));  
      //console.log(cloned);

      // If data is flat, prepare data with recursive function.
      // this.nodes = this.prepareData ? this._getPreparedData(cloned) : this.data;
      //this.items = this.nodes;
      //console.log(this.nodes);
    })
  }

  
}
