import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { environment } from "src/environments/environment";
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Bid } from 'src/app/bids/model/bid';

let headers = new HttpHeaders({
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
  'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization'
});

const apiUrl = 'http://167.71.241.236:5003';


@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private storage: AngularFireStorage, private http: HttpClient, private afs: AngularFirestore, private auth: AuthenticationService) { }

 productSearch(body)
 {
  headers = headers.append('Content-Type', 'application/json');
   return this.http.post(`${apiUrl}/products`, body, {headers})
 }

 imageSearch(body)
 {
   return this.http.post(`${apiUrl}/imageSearch`, body)
 }

 getProductById(id){
  return this.afs.collection(`${environment.firestorePath}/Products`).doc(id).get();
 }

 bidsColletion() : AngularFirestoreCollection<Bid>{
   return this.afs.collection(`${environment.firestorePath}/Bids`);
 }


}
