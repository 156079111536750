import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, startWith, tap, filter } from 'rxjs/operators';
import { User } from '../../user/model/user';
import { auth } from 'firebase';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  user: Observable<User | null>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    //private notify: NotifyService,
    //public remoteconfig: RemoteconfigService
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {        
        if (user) {
          return this.afs.doc<User>(`${environment.firestorePath}/users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
      // tap(user => localStorage.setItem('user', JSON.stringify(user))),
      // startWith(JSON.parse(localStorage.getItem('user')))
    );
  }

  ////// OAuth Methods /////

  async googleLogin(useraf: any) {
    const provider = new auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
    // const credential = await this.afAuth.auth.signInWithPopup(provider);
    // return this.updateUserData(credential.user, useraf);
  }

  githubLogin() {
    const provider = new auth.GithubAuthProvider();
    return this.oAuthLogin(provider);
  }

  facebookLogin() {
    const provider = new auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }

  twitterLogin() {
    const provider = new auth.TwitterAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider: any) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then(credential => {
        console.log('Welcome to Google Login');
        //this.notify.update('Welcome to Auufa!!!', 'success');
        return credential;
      })
      .catch(error => this.handleError(error));
  }

  //// Anonymous Auth ////

  anonymousLogin() {
    return this.afAuth.auth
      .signInAnonymously()
      .then(credential => {
        //this.notify.update('Welcome to Auufa!!!', 'success');
        return credential; // if using firestore
      })
      .catch(error => {
        this.handleError(error);
      });
  }

  //// Email/Password Auth ////

  emailSignUp(email: string, password: string, useraf: any) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(credential => {
        //this.notify.update('Welcome new user!', 'success');
        return this.updateUserData(credential.user, useraf); // if using firestore
      })
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(credential => {
        //this.notify.update('Welcome back!', 'success');
        return credential;
      })
  }

  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification();
  }

  checkVerification(){
   return this.afAuth.user;
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    const fbAuth = auth();

    return fbAuth
      .sendPasswordResetEmail(email)
      .then(() => console.log('Password update email sent', 'info'))
      .catch(error => this.handleError(error));
  }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(['auth/login']);
      // this.remoteconfig.token$ = new BehaviorSubject(undefined);
    });
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    //this.toastr.error(error.message);
    console.log(error.message, 'error');
  }

  // Sets user data to firestore after succesful login
  updateUserData(user: User, useraf: any) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(
      `${environment.firestorePath}/users/${user.uid}`
    );
    // let sub = ''
    // if(useraf.role === 'Manufacturer')
    // {  
    //   sub = 'plana';
    // }

    const data: User = {
      uid: user.uid,
      email: user.email || null,
      username:useraf.username ? useraf.username : "",
      displayName: user.displayName || useraf.fname+' '+useraf.lname || 'Auser',
      photoURL: user.photoURL || '',
      role: user.role || '',
      industry: user.industry || '',
      youAre: user.youAre || '',
      subscriptionId: user.subscriptionId || '',
      plan: user.plan || '',
      profileCompleted: user.profileCompleted || false,
      stage: user.stage || ''
    };
    
    //this.remoteconfig.createUser(data);

    return userRef.set(data);
    
  }

  getIndustry(industry){
      return this.afs.collection(`${environment.firestorePath}/design_industry`, ref => ref.where('parentId', '==', industry)).snapshotChanges(); 
  }

  getsubscription(role: any){
    //return this.afs.collection(`${environment.firestorePath}/subscription`, ref => ref.where('role', '==', role)).valueChanges();

    return this.afs.collection(`${environment.firestorePath}/subscription`, ref => ref.where('role', '==', role)).snapshotChanges();
  }
}
