// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  firestorePath: 'Development/v1', 
  firestorePath_data: 'development/ZmNgCjdfee9gbi4pxsoY', 
  // firebaseConfig : {
  //   apiKey: "AIzaSyBO-9ZeyaZS9CyEPmcCMZ5ve_GwOz07MuU",
  //   authDomain: "auufa-82511.firebaseapp.com",
  //   databaseURL: "https://auufa-82511.firebaseio.com",
  //   projectId: "auufa-82511",
  //   storageBucket: "auufa-82511.appspot.com",
  //   messagingSenderId: "125695687687",
  //   appId: "1:125695687687:web:1c674c0872f5e6a7"
  // }

  firebaseConfig : {
    apiKey: "AIzaSyB1HTwxo3zsBCvYKjKhscl1AuGuGvFUX-A",
    authDomain: "auufa-be051.firebaseapp.com",
    databaseURL: "https://auufa-be051.firebaseio.com",
    projectId: "auufa-be051",
    storageBucket: "auufa-be051.appspot.com",
    messagingSenderId: "588351832132",
    appId: "1:588351832132:web:6f1134fa0caad3bfcd3c11",
    measurementId: "G-W22876FF6T"
  }
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
