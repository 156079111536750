import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './guards/auth-guard.service';
import { EventsComponent } from './components/events/events.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { EventListComponent } from './components/event-list/event-list.component';
import { EventCardComponent } from './components/event-card/event-card.component';
import { SearchComponent } from './components/search/search.component';
import { database } from 'firebase';
import { DatabaseComponent } from './components/database/database.component';
import { SignInInformationComponent } from './components/sign-in-information/sign-in-information.component';
import { AccountAccessPermissionComponent } from './components/account-access-permission/account-access-permission.component';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { SubscriptionPaymentComponent } from './components/subscription-payment/subscription-payment.component';
import { AccountOverviewComponent } from './components/account-overview/account-overview.component';
import { FirmsProfileComponent } from './components/firms-profile/firms-profile.component';


const routes: Routes = [
  { path: "", redirectTo:"auth/login", pathMatch:"full"},
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'events',
    component: EventsComponent
  },
  {
    path: 'events-list',
    component: EventListComponent
  },
  {
    path: 'events-card',
    component: EventCardComponent
  },
  {
    path:'subscriptions',
    component: SubscriptionComponent
  },
  {
    path:'search',
    component: SearchComponent
  },
  {
    path: 'database',
    component: DatabaseComponent
  },
   //{
   // path: 'signin-information',
   //component: SignInInformationComponent
   //},
  {
    path: 'access-permission',
    component: AccountAccessPermissionComponent
  },
   //{//
    // path: 'account-settings',
    // component: AccountSettingsComponent
   //},//
  {
    path: 'subscription-payment',
    component: SubscriptionPaymentComponent
  },
  {
    path: 'firms-profile',
    component: FirmsProfileComponent
  },
  {
    path: 'account-overview',
    component: AccountOverviewComponent
  },
  { path: 'auth', loadChildren: () => import('../app/authentication/authentication.module').then(mod => mod.AuthenticationModule)},
  { path: 'user', loadChildren: () => import('../app/user/user.module').then(mod => mod.UserModule)},
  { path: 'search', loadChildren: () => import('./search/search.module').then(m => m.SearchModule) },
  { path: 'upload', loadChildren: () => import('./upload/upload.module').then(m => m.UploadModule) },
  { path: 'bids', loadChildren: () => import('./bids/bids.module').then(m => m.BidsModule) },
  { path: 'product', loadChildren: () => import('./product/product.module').then(m => m.ProductModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
