import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, take, tap, switchMap } from 'rxjs/operators';

import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';


import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from '../authentication/services/authentication.service';
import { User } from '../user/model/user';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  user: Observable<User | null>;

  constructor(
    private auth: AuthenticationService,
    private router: Router,

    
  ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.user.pipe(
      take(1),
      map(user => !!user),
      tap(loggedIn => {
        //console.log(this.auth.user);
        if (!loggedIn) {
          console.log('access denied');
          this.router.navigate(['/auth/login']);

        }
        else {
          if (this.auth.user) {
            //Navigate to specific defualt page.
          }
        }
      })
    );
  }
}