import { Component, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/user/model/user';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { AngularFireUploadTask } from '@angular/fire/storage';
import { Bid } from 'src/app/bids/model/bid';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { Options } from 'ng5-slider';
import { SearchService } from 'src/app/search/services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  onEnter = "Loading"
  public searchForm: FormGroup;
  public bidForm: FormGroup;
  public records = [];
  public csiCodes = [];
  public category = [];
  public filcsicodes = [];
  public filcategory = [];
  public body: any;
  public modalheading = '';
  public user: User;
  public product: any;
  public pageLoading = false;
  public datePosted = "";
  public searchType = "Image";
  public fileLabel = "Please Upload Image";
  showClear = false;
  imgURL: any;
  imagePath;
  keyWord = "";
  schecked = false;
  showcodes: boolean = false;
  chilcodes: any = [];

  value: number = 100;
  highValue: number = 300;
  options: Options = {
    floor: 0,
    ceil: 2000
  };



  constructor(private formbuilder: FormBuilder, private toastr: ToastrService, public eleref: ElementRef, private auth: AuthenticationService, private searchService: SearchService) {


    let now = moment();
    this.datePosted = now.format('l');

    this.searchForm = this.formbuilder.group({
      searchtext: [''],
      category: [''],
      csicodes: ['']
    }),
      //bid modal form
      this.bidForm = this.formbuilder.group({
        bidQuantity: ['', Validators.required],
        remark: ['', Validators.required]
      })

  }

  ngOnInit() {
    if (this.auth.user) {
      this.auth.user.subscribe((Currentuser) => {
        this.user = Currentuser;
      });
    }

    //this.search(this.searchForm.value);
    this.search("");
  }

  search(value) {
    // console.log(value.target.value);
    var searchtext = value ? value.target.value : "";
    this.pageLoading = false;
    this.searchType = 'Image';
    if (this.searchType != 'Text') {
      if (value.searchtext != '') {
        this.showClear = true;
      }
      this.body = "{\"search\": \"" + searchtext + "\", \"category\":\"\", \"csiCode\":\"\"}";
      //zalert(this.body);
      this.getresults(this.body, [], [], this.searchType);
    }
    // else 
    // {
    //   this.getresults(this.body, [], [], this.searchType);
    // }
  }

  getresults(body, csiCode, category, searchtype) {

    if (category.length > 0 || csiCode.length > 0) {
      this.showClear = true;
    }
    // else
    // {
    //   this.showClear = false;
    // }
    let url = "";
    if (searchtype != 'Text') {
      console.log('text search');

      //url = "http://167.71.241.236:5002/products";
      //headers = headers.append('Content-Type', 'application/json');
      console.log(body);

      this.searchService.productSearch(body).subscribe((data) => {
        this.createResults(data, csiCode, category);
      });
    }
    else //Image Search
    {
      //url = "http://167.71.241.236:5002/imageSearch";
      this.searchService.imageSearch(body).subscribe((data) => {
        this.createResults(data, csiCode, category);
      });
    }
  }

  createResults(data, csiCode, category) {
    this.pageLoading = true;
    this.records = JSON.parse(JSON.stringify(data));
    if (this.records.length != 0)
      this.showcodes = false;
    //console.log(this.records);
    this.category = [];
    this.csiCodes = [];
    for (var key in this.records) {
      //var csicode = this.records[key].csiCode;
      var cat = this.records[key].category;
      // if ((this.csiCodes.indexOf(csicode) === -1)) {
      //   this.csiCodes.push(csicode);
      // }
      if (this.category.indexOf(cat) === -1) {
        if (cat != '')
          this.category.push(cat)
      }
    }
    //filtering for csicodes

    //filtering for category
    if (category) {
      var rec = this.records;
      for (var key in category) {
        this.records = rec.filter(function (a) {
          return a.category === category[key];
        })
        //this.records.push(this.records)
      }
    }
    this.csiCodes = [];
    for (var key in this.records) {
      var csicode = this.records[key].csiCode;
      if ((this.csiCodes.indexOf(csicode) === -1)) {
        this.csiCodes.push(csicode);
      }
    }
    if (csiCode) {
      var rec = this.records;
      for (var key in csiCode) {
        this.records = rec.filter(function (a) {
          return a.csiCode === csiCode[key];
        })
        //this.records.push(this.records)
      }
    }
  }

  filterCSICode(csicode) {
    if (this.filcsicodes.indexOf(csicode) === -1) {
      this.filcsicodes.push(csicode);
      this.getresults(this.body, this.filcsicodes, this.filcategory, this.searchType);
    }
    else {
      var index = this.filcsicodes.indexOf(csicode);
      if (index > -1) {
        this.filcsicodes.splice(index, 1);
        console.log(this.filcsicodes);
      }

      this.getresults(this.body, this.filcsicodes, this.filcategory, this.searchType);
    }
  }

  filterCategory(category) {
    if (this.filcategory.indexOf(category) === -1) {
      this.filcategory.push(category);
      this.getresults(this.body, this.filcsicodes, this.filcategory, this.searchType);
    }
    else {
      var index = this.filcategory.indexOf(category);
      if (index > -1) {
        this.filcategory.splice(index, 1);
      }
      this.getresults(this.body, this.filcsicodes, this.filcategory, this.searchType);
    }
  }

  setmodal(product) {
    this.modalheading = product.name;
    console.log(product);
    this.bidForm.reset();
    this.searchService.getProductById(product.id).subscribe((prod) => this.product = prod.data())
    // var bid = this.afs.collection('Development/'+'v1/'+'Products').valueChanges();
    //     bid.subscribe((b) => { this.product = JSON.parse(JSON.stringify(b)); console.log(this.product.length); });
    //console.log('uid '+ this.eleref.nativeElement.querySelector('userid'));
  }

  saveBid(value) {

    console.log(this.product);
    const bid: Bid = {
      Architect: this.user.email,
      Manufacturer: this.product.manufacturerID,
      quantity: value.bidQuantity,
      remark: value.remark,
      productname: this.product.name,
      url: this.product.url,
      price: this.product.price,
      dateposted: this.datePosted,
      timestamp: new Date().getTime()
    }
    //console.log(value);
    //console.log(bid);
    this.setbidding(bid);
    this.toastr.success('Your bid has been submitted to the Manufacturer');
  }

  setbidding(bid: Bid) {
    const bids: AngularFirestoreCollection<Bid> = this.searchService.bidsColletion();
    bids.add(bid);
  }

  clear() {
    this.category = [];
    this.csiCodes = [];
    this.pageLoading = false;
    this.imgURL = undefined;
    this.showClear = false;
    this.searchForm.reset();
    this.body = "{\"search\": \"" + this.keyWord + "\", \"category\":\"\", \"csiCode\":\"\"}";
    this.getresults(this.body, [], [], 'Image');
  }
  // Main task
  task: AngularFireUploadTask;

  uploadFile(event: FileList) {
    // The File object
    let file = event.item(0);
    this.fileLabel = file.name;
    console.log(file);

    var reader = new FileReader();
    this.imagePath = file;
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }

    //const efile = 
    // Client-side validation example
    // if (file.type.split('/')[0] !== 'image') {
    //   console.error('Unsupported file type! ');
    //   return;
    // }
    //let fileBody = {'file': file };

    // // The storage path
    // const path = `search/${new Date().getTime()}_${file.name}`;

    // // Totally optional metadata
    // const customMetadata = { app: 'My AngularFire-powered PWA!' };

    // // The main task
    // this.task = this.storage.upload(path, file, { customMetadata });
    //var blob = new Blob([file]);
    const formData = new FormData();
    formData.append("file", file);
    this.body = formData;
    this.searchType = 'Text';
    this.showClear = true;
    this.pageLoading = false;
    //console.log(formData.getAll('file'));
    this.getresults(this.body, [], [], this.searchType);
    //headers = headers.append('content-type', 'multipart/form-data');

    // this.http.post("http://167.71.241.236:5002/imageSearch", formData).subscribe((data) => {
    //   this.pageLoading = true;
    //   this.records = JSON.parse(JSON.stringify(data));
    //   console.log(data);
    // }); 


    // // The main task
    // this.task = this.storage.upload(path, file, { customMetadata });

    // // Progress monitoring
    // this.percentage = this.task.percentageChanges();
    // this.snapshot = this.task.snapshotChanges().pipe(
    //   tap(snap => {
    //     if (snap.bytesTransferred === snap.totalBytes) {
    //       // Update firestore on completion
    //       this.db.collection('photos').add({ path, size: snap.totalBytes });
    //     }
    //   }),
    //   finalize(() => this.downloadURL = this.storage.ref(path).getDownloadURL() )
    // );

  }

  toggleSearch(search) {
    console.log(search);
    if (search === 'Text') {
      this.searchType = 'Image';
    }
    else {
      this.searchType = 'Text';
    }
  }


  setKeyword(key) {
    this.keyWord = key;
    let value = { searchtext: key, category: '', csicodes: '' };
    this.search(value);
  }

  clickN(event: any) {
    console.log(event);
    var csi = event['id'].trim().split(' ').join('');
    this.chilcodes = event['CHILDREN'];
    this.showcodes = true;
    this.body = "{\"search\": \"" + this.keyWord + "\", \"category\":\"\", \"csiCode\":\"" + csi + "\"}";
    this.getresults(this.body, [], [], '');
  }

  priceGauge() {
    this.body = "{\"search\": \"" + this.keyWord + "\", \"category\":\"\", \"csiCode\":\"\", \"min_price\":" + this.value + ", \"max_price\":" + this.highValue + "}";
    this.getresults(this.body, [], [], '');
  }


  openFilePicker() {
    let element: HTMLElement = document.querySelector('input[type="file"]') as HTMLElement;
    element.click();
  }

}
