import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import * as $ from 'jquery';
@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.scss']
})
export class DatabaseComponent implements OnInit {

  constructor( @Inject(DOCUMENT) private document: Document) {
  }
  

  ngOnInit() {
    this.document.body.classList.add('databage-page');
    $(document).ready(function(){ 
      $(".mobile-menu").click(function(){
        $("body").toggleClass("menu-active");
       })
       $(".navbar-nav li a:not('.dropdown-toggle'), ul.menu.menu--main li a:not('.dropdown-toggle'), a:not('.dropdown-toggle')").click(function(){
        $("body").removeClass("menu-active");
       })
  });
  }

}
