import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

date = new Date();

  constructor() { }

  ngOnInit() {
    $(document).ready(function(){ 
      $(".mobile-menu").click(function(){
        $("body").toggleClass("menu-active");
       })
       $(".navbar-nav li a:not('.dropdown-toggle'), ul.menu.menu--main li a:not('.dropdown-toggle'), a:not('.dropdown-toggle')").click(function(){
        $("body").removeClass("menu-active");
       })
  });

  }

}
