import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthenticationService } from '../authentication/services/authentication.service';
import { environment } from "src/environments/environment";
import { HttpHeaders } from '@angular/common/http';

let headers = new HttpHeaders({
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
  'Access-Control-Allow-Headers': 'Content-Type, X-Auth-Token, Origin, Authorization'
});

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  products: any;
  clientRef: any;

  constructor(private afs: AngularFirestore, private auth: AuthenticationService) { 

    // this.afs.collection(`${environment.firestorePath}/Products`).get().subscribe( ( products ) => {
    //   products.docs.map( ( product ) => {
    //     this.afs.collection(`${environment.firestorePath}/Products`).doc( product.id ).update( { price: parseFloat(  product.data().price )});
    //   });
    // });

  }

  getCollection(collection){
    return this.afs.collection(`${environment.firestorePath}/${collection}`).valueChanges();
  }

  getDoc(collection, doc){
    return this.afs.collection(`${environment.firestorePath}/${collection}`).doc(doc).valueChanges();
  }

  updateCollection(){
    return this.afs.collection(`${environment.firestorePath}/products`).snapshotChanges().pipe().subscribe((data)=>{})
  }

}
